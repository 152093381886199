(function () {
	'use strict';

	angular
		.module('app')
		.factory('googleSheetsConfig', [
			'$sce',
			'$location',
			'utilities',
			googleSheetsConfig,
		]);

	function googleSheetsConfig($sce, $location, utilities) {
		return {
			apiConnection: apiConnection,
			config: config,
			fieldMap: fieldMap,
			unusedMap: unusedMap,
			allowHTMLMap: allowHTMLMap,
			hiddenFieldMap: hiddenFieldMap,
			readOnlyFieldMap: readOnlyFieldMap,
		};

		function apiConnection() {
			var platform = utilities.getDBKPlatform();
			return {
				clientID: '',
				calendarApiKey: '',
				authIsRedirect:
					platform === 'dbkfm' ||
					platform === 'dbkfmjs' ||
					platform === 'dbkfmwd'
						? true
						: false,
				authRedirectFunction:
					platform === 'dbkfmjs' || platform === 'dbkfmwd'
						? function (authURL, callback) {
								utilities.filemakerExternalOauth(
									platform,
									authURL,
									'code=',
									callback
								);
							}
						: false,
			};
		}

		function config() {
			return {
				id: 9,
				name: 'Google Sheets',
				propertyName: 'googleSheets',
				available: true, // A boolean wether this source type should be available for users to enable
				editable: false,
				isPrimary: true,
				async: true,
				seperateSchedules: true, // Are there many calendars per sign in / account or set to false if we map an object / table to each calendar
				localSchedules: true, // If schedules should be created in DayBack or on the datasource
				hasEventCache: true,
				colorType: 'rgba', // 'hex' or 'rgb'
				borderColor: 'black',
				color: 'blue',
				textColor: 'white',
				iconPath: `${_CONFIG.DBK_BASEURL}images/gsheetsBig.png`,
				sourceHelp: $sce.trustAsHtml(
					'Click "Add New Calendar" for each Google Sheet you would like to connect to. Then configure that connection by completing the information in "Calendar Info" and in "Field Mapping".'
				),
				parentSourceHelp: $sce.trustAsHtml(
					'Please select a calendar to the left in order to change that calendar’s settings.'
				),
				fieldMappingHelp: $sce.trustAsHtml(
					"<h4>You can choose the label to appear next to a field when editing an event.</h4>If you don't want a particular field to appear just un-check the show check-box beside the field. If you don't see a checkbox it means that field is required for the calendar to work."
				),
				eventActionsHelp: $sce.trustAsHtml(
					'<h4>Change what happens when users click on, edit, or delete an event.</h4><p>Customize your calendar by altering the editing behavior in DayBack. Create actions that fire when you drag an event or hover over one. Reference event attributes in your action by wrapping your field names in two square brackets like this: [[id]]</p><p>Learn more: <a href="https://docs.dayback.com/article/20-event-actions" target="_blank">Creating Event Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, many of which are event actions you can paste in here. Popular actions include <a href="https://dayback.com/listing/link-related-events/" target="_blank">cascading changes through linked events downstream</a>.</p>'
				),
				customActionsHelp: $sce.trustAsHtml(
					'<h4>Add custom buttons to the event’s popover drawer.</h4><p>Take action right from the calendar by adding your own buttons beside an event. Buttons can interact with other apps, navigate to details about the event, or bring up forms you design.</p><p>Learn more: <a href="https://docs.dayback.com/article/5-custom-actions" target="_blank">Creating Button Actions</a></p><h4>Need examples?</h4><p>Browse our <a href="https://dayback.com/extensions/" target="_blank">library of the most popular customizations</a>, several of which are buttons you can paste in here. Popular buttons include <a href="https://dayback.com/listing/zoom-meetings-calendar/" target="_blank">scheduling Zoom meetings</a>.</p>'
				),
				// maxSources: 1,
				showAuthButton: true,
				authButton: `${_CONFIG.DBK_BASEURL}images/authorize-google.png`,
				eventFocus: null,
				refresh: null,
				useISOTimestamp: true,
				useDefaultFieldMap: false,
				customActionsEditable: true,

				settings: {
					//setting: the name of the setting, visible: wether or not the setting is user facing, defaultValue: the default value if any
					id: {
						setting: 'id',
						visible: false,
						defaultValue: new Date().getTime(),
					},
					sourceTypeID: {
						setting: 'sourceTypeID',
						visible: false,
						defaultValue: 10,
					},
					name: {
						setting: 'name',
						visible: true,
						displayValue: 'Calendar Name',
						format: 'name',
						defaultValue: 'New Google Sheets Calendar',
					},
					sheetID: {
						setting: 'sheetID',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Google Sheet URL',
						placeholder: 'Full URL or Sheet ID',
						defaultValue: '',
						helptext:
							'The URL or ID of the google sheet. If you paste the full url for the sheet the ID will be extracted automatically.',
					},
					sheetName: {
						setting: 'sheetName',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Sheet Tab Name',
						placeholder: '(optional)',
						defaultValue: '',
						helptext:
							'The user specified name for an individual sheet tab you want to retrieve data from. If none is specified the first sheet tab will be assumed.',
					},
					firstDataRow: {
						setting: 'firstDataRow',
						visible: true,
						scheduleOnly: true,
						displayValue: 'First Data Row',
						format: 'number',
						defaultValue: 1,
						helptext:
							"The first row in the sheet where data appears. For example if you have a header row you might set this to 2 so the header row isn't retrieved.",
					},
					lastDataRow: {
						setting: 'lastDataRow',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Last Data Row',
						format: 'number',
						placeholder: '(optional)',
						defaultValue: '',
						helptext:
							'The last row to be retrived from the sheet. This is optional and should only be set if you would like to limit the rows being shown on the calendar.',
					},
					backgroundColor: {
						setting: 'backgroundColor',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Background Color',
						defaultValue: 'rgba(244, 244, 244, 0.85)',
						format: 'colorPicker',
						helptext:
							'The status color to used for newly created events or events with no status. We recommend a little transparency here and rgba colors are supported where the last value is the transparency (i.e. in this color...<br><br> \
									<div class="exampleCode">rgba(244, 244, 244, 0.85)</div><br> \
							 ...0.85 is the transparency on a scale of 0 to 1, meaning it is nearly opaque).',
					},
					readOnly: {
						setting: 'readOnly',
						visible: false,
						displayValue: 'Read-Only',
						defaultValue: false,
						format: 'yesno',
					},
					readOnlyNote: {
						setting: 'readOnlyNote',
						visible: true,
						displayValue: 'Read-Only',
						defaultValue: '',
						helptext:
							'Google Sheets are read-only for now. To edit or add additional data you must modify the sheet itself.',
						format: 'note',
					},
					htmlDescription: {
						setting: 'htmlDescription',
						visible: false,
						scheduleOnly: true,
						displayValue: 'Allow HTML In Description',
						defaultValue: false,
						format: 'yesno',
						helpText:
							'Setting this to true will allow HTML to be used in the description field and rendered in the popover. This will make the description field read-only.',
					},
					allowDayback: {
						setting: 'allowDayback',
						visible: false,
						displayValue: 'Allow defend your time',
						defaultValue: true,
						format: 'yesno',
						helptext:
							'Setting this to false will hide the "defend your time" checkbox when creating new events in this source.',
					},
					isUnavailable: {
						setting: 'isUnavailable',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Unavailable',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to true will render any events on this source as unavailable time on the calendar rather than regular events. <a href="https://docs.dayback.com/article/258-availability" target="_blank">Learn more about availability.</a>',
					},
					isMeasureOnly: {
						setting: 'isMeasureOnly',
						visible: true,
						scheduleOnly: true,
						displayValue: 'For Analytics Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from displaying but will allow the data from the events to be used in analytics charts.',
					},
					isMapOnly: {
						setting: 'isMapOnly',
						visible: false,
						scheduleOnly: true,
						displayValue: 'For Map Only',
						defaultValue: false,
						format: 'yesno',
						helptext:
							'Setting this to yes will hide these events from the calendar but will allow the data to be used in maps.',
					},
					fileDateFormat: {
						setting: 'fileDateFormat',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Sheet Date Format',
						defaultValue: 'YYYY-MM-DD',
						helptext:
							'A <a href="https://momentjs.com/docs/#/displaying/format/" target="_blank">momentjs</a> date format string representing how dates are stored in your Google Sheet.',
					},
					fileTimeFormat: {
						setting: 'fileTimeFormat',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Sheet Time Format',
						defaultValue: 'h:mm A',
						helptext:
							'A <a href="https://momentjs.com/docs/#/displaying/format/" target="_blank">momentjs</a> time format string representing how times are stored in your Google Sheet with dates.',
					},
					isPrimary: {
						setting: 'isPrimary',
						visible: false,
						scheduleOnly: true,
						displayValue: 'Default calendar for new items',
						defaultValue: false,
						format: 'primaryCalendar',
					},
					defaultResource: {
						setting: 'defaultResource',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Show As Resource',
						defaultValue: '',
						format: 'defaultResource',
					},
					defaultDrawer: {
						setting: 'defaultDrawer',
						visible: true,
						scheduleOnly: true,
						displayValue: 'Default popover drawer',
						defaultValue: '',
						format: 'select',
						options: [
							{id: '', label: ''},
							{id: 'dateStart', label: 'Start'},
							{id: 'dateEnd', label: 'End'},
							{id: 'calendar', label: 'Calendar'},
							{id: 'location', label: 'Location'},
							{id: 'resource', label: 'Resource'},
							{id: 'status', label: 'Status'},
							{id: 'customFields', label: 'Custom Fields'},
							{id: 'action', label: 'Button Actions'},
						],
						helptext:
							'Optional. Choose a drawer that should be shown automatically when clicking on an event.</br> <a href="https://docs.dayback.com/article/167-drawers">Learn more about drawers here.</a>',
					},
				},
			};
		}

		function fieldMap() {
			return {
				title: {
					setting: 'title',
					visible: true,
					displayValue: 'Title Display Summary',
					defaultValue: 'Title, Description',
					hideAdvanced: true,
					required: true,
					unused: false,
					helptext:
						'This is the text that appears on the calendar for each event. You can combine several fields here, seperated by a comma. These fields will be seperated by carriage returns in the Schedule views. You can specify which string to substitute for the carriage return in the other views in "Event Styles."' +
						'<p><strong>Standard field names that can be referenced are:</strong>' +
						'<ul>' +
						'<li>Title</li>' +
						'<li>Description</li>' +
						'<li>AllDay</li>' +
						'<li>Start</li>' +
						'<li>End</li>' +
						'<li>Location</li>' +
						'<li>Resource</li>' +
						'<li>Status</li>' +
						'</ul>' +
						'Any custom field available to the calendar may also be referenced using the ID or the field name assigned to that custom field.' +
						'</p>' +
						'<p>' +
						'Styling adjustments can be made with a special "dbk-css" tag. Wrapping fields in this tag will allow inline styling or CSS classes.' +
						'</p>' +
						'<div class="exampleCode">' +
						'&lt;dbk-css style="color: red;"&gt;Title&lt;/dbk-css&gt;, &lt;dbk-css class="large-text"&gt;Description&lt;/dbk-css&gt;' +
						'</div>' +
						'<br />' +
						'<p>You’ll find more instructions on styling events with CSS <a href="https://docs.dayback.com/article/111-event-styles" target="_blank">here</a>.</p>',
				},
				eventID: {
					setting: 'eventID',
					visible: true,
					displayValue: 'Event id',
					defaultValue: '',
					labelValue: 'Event id',
					required: true,
					unused: false,
					helptext:
						'The primary key or unique ID in your row. We reccommend generating a UUID for this. <div>' +
						'<p>1. Open the script editor for creating Google Apps Script (Tools -> Script Editor)</p>' +
						'<p>2. Add the following function to the script and save it</p>' +
						'<pre>function uuid() {<br />' +
						'  return Utilities.getUuid();<br />' +
						'}</pre>' +
						'<p>3. Add =uuid() to a cell in your sheet and duplicate for every row</p>' +
						'</div>',
				},
				titleEdit: {
					setting: 'titleEdit',
					visible: true,
					displayValue: 'Title',
					defaultValue: '',
					labelValue: 'Enter Title',
					required: true,
					unused: false,
					helptext:
						'This is the data shown in the event popover as the title or summary.',
				},
				description: {
					setting: 'description',
					visible: true,
					displayValue: 'Description',
					defaultValue: '',
					labelValue: 'Description',
					required: false,
					unused: false,
					helptext:
						'This is the data shown in the event popover as the description (a longer text field).',
				},
				allDay: {
					setting: 'allDay',
					visible: false,
					displayValue: 'AllDay',
					defaultValue: '',
					labelValue: 'All Day',
					required: false,
					unused: false,
					helptext: '',
				},
				start: {
					setting: 'start',
					visible: true,
					displayValue: 'Start',
					defaultValue: '',
					labelValue: 'Start',
					required: true,
					unused: false,
					helptext:
						'The start date (or date and time) for your event: data must match the date format specified in "Sheet Date Format" in Calendar Info. Note that if you only have one date for your event (a deadline, for example) that goes here.',
				},
				end: {
					setting: 'end',
					visible: true,
					displayValue: 'End',
					defaultValue: '',
					labelValue: 'End',
					required: false,
					unused: false,
					helptext:
						'The end date (or date and time) for your event: data must match the date format specified in "Sheet Date Format" in Calendar Info.',
				},
				location: {
					setting: 'location',
					visible: true,
					displayValue: 'Location',
					defaultValue: '',
					labelValue: 'Location',
					required: false,
					unused: false,
					helptext:
						'The data that holds your event "location" --this is also the what DayBack will use to generate a map.',
				},
				geocode: {
					setting: 'geocode',
					visible: false,
					displayValue: 'GeoCode',
					defaultValue: 'geocode',
					labelValue: 'GeoCode',
					required: false,
					unused: false,
					helptext:
						'Latitude and longitude values separated by a comma',
				},
				resource: {
					setting: 'resource',
					visible: true,
					displayValue: 'Resource',
					defaultValue: '',
					labelValue: 'Resource',
					required: false,
					unused: false,
					helptext:
						'The data that holds your "resource" which could be a person, a room, or a piece of equipment. Multiple values can be separated with a comma ie. "Truck 1,Truck2".',
				},
				status: {
					setting: 'status',
					visible: true,
					displayValue: 'Status',
					defaultValue: '',
					labelValue: 'Status',
					required: false,
					unused: false,
					helptext:
						'The data that holds your event "status" --this is also the what DayBack will use to color code your events. This need not be a literal status and can be anything you\'d like to color by provided the field isn\'t used elsewhere in this list. Multiple values can be separated with a comma ie. "Active,Pending".',
				},
				tags: {
					setting: 'tags',
					visible: false,
					displayValue: 'Tags',
					defaultValue: '',
					labelValue: 'Tags',
					required: false,
					unused: false,
					helptext: '',
				},
				attendees: {
					setting: 'attendees',
					visible: false,
					displayValue: 'Attendees',
					labelValue: 'Attendees',
					defaultValue: '',
					required: false,
					unused: false,
					helptext: 'List of attendees if any exist.',
				},
				metadata: {
					setting: 'metadata',
					visible: true,
					displayValue: 'Metadata',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'This is used to pass additional values into an event. The data passed in should be in standard JSON format. An example for using linked events using this metadata would be {"linkedID": "SomeUniqueID", "linkedRange": "2020-01-15,2020-03-15"}',
				},
				featuredImage: {
					setting: 'featuredImage',
					visible: true,
					displayValue: 'Featured Image URL',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'URL for a featured image shown when viewing event in the popover. <a href="https://docs.dayback.com/article/166-featured-images-thumbnails" target="_blank">More info...</a>',
				},
				featuredImageClass: {
					setting: 'featuredImageClass',
					visible: true,
					displayValue: 'Featured Image Class',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'CSS class for a featured image shown when viewing event. This is any class name you want and can be used when creating custom CSS. <a href="https://docs.dayback.com/article/166-featured-images-thumbnails" target="_blank">More info...</a>',
				},
				featuredImageThumbnail: {
					setting: 'featuredImageThumbnail',
					visible: true,
					displayValue: 'Featured Image Thumbnail URL',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'URL for a featured image thumbnail shown on events in Horizon view. <a href="https://docs.dayback.com/article/166-featured-images-thumbnails" target="_blank">More info...</a>',
				},
				featuredImageThumbnailClass: {
					setting: 'featuredImageThumbnailClass',
					visible: true,
					displayValue: 'Featured Image Thumbnail Class',
					defaultValue: '',
					required: false,
					unused: false,
					helptext:
						'CSS class for a featured image thumbnail shown on events. This is any class name you want and can be used when creating custom CSS. <a href="https://docs.dayback.com/article/166-featured-images-thumbnails" target="_blank">More info...</a>',
				},
				customFields: {
					setting: 'customFields',
					visible: true,
					uiOnly: true,
					displayValue: 'Custom Fields',
					defaultValue: '',
					labelValue: 'Custom Fields',
					required: false,
					unused: false,
					helptext: '',
				},
			};
		}

		function unusedMap() {
			return {
				contactID: true,
				contactName: true,
				projectID: true,
				projectName: true,
				parentListID: true,
				parentListName: true,
				done: true,
			};
		}

		function allowHTMLMap() {
			return {};
		}

		function hiddenFieldMap() {
			return {};
		}

		function readOnlyFieldMap() {
			return {};
		}
	}
})();
